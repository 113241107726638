.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: Roboto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* body {
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* body::-webkit-scrollbar {
  display: none;
} */


.modal-open {
  overflow: hidden;
  overflow-y: inherit;
  padding-right: 0 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: inherit;
}

.slick-dots {
  position: absolute;
  bottom: -50px !important;
  z-index: 10;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}

.slick-dots li {
  position: relative;
  bottom: 50px;
  display: inline-block;

  width: 80px;
  height: 10px;
  margin: 0 35px !important;
  padding: 0;

  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 80px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  background-color: #e11428;
  height: 3px !important;
  width: 80px !important;
  text-indent: -9999px;
  overflow: hidden;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black !important;
}


.popup-banner-close {
  top: -30px;
  right: -210px;
  position: absolute;
  color: white;
  cursor: pointer;
  font-size: 1.5em;
}

.popup-banner-container {
  padding: 0;
  background-size: contain;
  display: flex;
  align-items: center;
}
.popup-banner-container img{
  width: 150%;
  object-fit: contain,
}

@media (max-width: 600px) {
  .popup-banner-container{
    padding: 0;
  }
  .popup-banner-container img{
    width: 100%;
    object-fit: contain,
  }
  .popup-banner-close {
    top: -10px;
    right: 0;
  }
}


.top-to-btm{
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 20;
}
.icon-style{
  /* background-color: #551B54; */
  /* border: 2px solid #fff;
  border-radius: 50%; */
  height: 48px;
  width: 48px;
  color: #050303 !important;
  cursor: pointer;
  /* animation: movebtn 3s ease-in-out infinite; */
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  color: #444444 !important;
  background: #FFFFFF;
  /* border: 2px solid #551B54; */
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}